<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 18:44:51
 * @LastEditTime: 2022-09-21 18:43:51
 * @LastEditors: cyy
 * @Description: 推广员》推广员管理》详情
 * @FilePath: \teacherdingding.com\src\views\coursePromotion\orderTongji.vue
-->
<template>
  <div id="promoterDetail">
    <div class="basic_data">
      <el-descriptions>
        <el-descriptions-item label="活动名称">
          {{ promoterGoodsList.name }}
        </el-descriptions-item>
        <el-descriptions-item label="事件类型">
          {{ promoterGoodsList.type == 1 ? '表单提交' : '商品购买' }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{
            promoterGoodsList.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
          }}
        </el-descriptions-item>
        <el-descriptions-item label="创建人">
          {{ promoterGoodsList.uname }}
        </el-descriptions-item>
        <el-descriptions-item label="商品">
          {{ promoterGoodsList.course_name }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="main main_menu">
      <div class="flex-center pl20 pr20 pt20">
        <div class="header">
          <div class="heard_top mr20">
            <el-date-picker
              size="medium"
              style="width: 292px"
              v-model="orderTime"
              type="daterange"
              @change="paytimepick"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div class="heard_top">
            <el-select
              style="width: 120px"
              v-model="post.status"
              placeholder="请选择"
              size="medium"
              class="mr20"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="heard_top">
            <el-select
              style="width: 140px"
              v-model="post.channel"
              placeholder="请选择"
              size="medium"
              class="mr20"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="heard_top input2">
            <el-select
              size="medium"
              style="width: 120px"
              v-model="post.filter_type"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="input3">
            <el-input
              v-model="post.search"
              placeholder="搜索"
              size="medium"
              @keyup.enter.native="post2 = Object.assign({}, post)"
            ></el-input>
          </div>
          <el-button
            type="primary"
            size="medium"
            @click="post2 = Object.assign({}, post)"
            class="ml10"
          >
            搜索
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="exportTable"
            class="ml20"
          >
            导出
          </el-button>
        </div>
      </div>
      <!-- 推广记录 -->
      <pagination2
        :option="post2"
        url="/CourseAdvertising/promotionDetails"
        class="pl20 pr20"
        @complete="complete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            @sort-change="sortChange"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="购买学员" prop="number" width="230">
              <template slot-scope="{ row }">
                <div class="cover flex-center">
                  <img class="img" :src="row.uphoto" alt="" />
                  <div class="text">
                    <div class="t1">{{ row.uname }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="真实姓名" prop="student_remarks">
              <template slot-scope="{ row }">
                {{ row.student_remarks || '-' }}
              </template>
            </el-table-column>

            <el-table-column label="投放平台" prop="channel">
              <template slot-scope="{ row }">
                {{ row.channel | getChannel }}
              </template>
            </el-table-column>

            <el-table-column label="手机号" prop="umobile"></el-table-column>

            <el-table-column
              label="购买时间"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
            >
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="实付">
              <template slot-scope="{ row }">￥{{ row.price }}</template>
            </el-table-column>

            <el-table-column label="支付状态" prop="price">
              <template slot-scope="{ row }">
                <div
                  :style="`color:${row.status == 1 ? '#3D80EF' : '#FF3535'}`"
                >
                  {{ row.status == 1 ? '支付成功' : '未支付' }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="来源链接"
              prop="submit_url"
              min-width="120px"
            >
              <template slot-scope="{ row }">
                {{ row.submit_url || '-' }}
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  @click="toOrder(row)"
                  :disabled="row.order_id == 0"
                >
                  查看订单
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
    <!-- 导出选择 -->
    <export-pop
      v-if="exportstatus"
      @submit="exportsubmit"
      :count="Number(count)"
      :dialogstatus.sync="exportstatus"
    ></export-pop>
  </div>
</template>

<script>
import exportPop from './exportPop'
export default {
  name: 'orderTongji',

  components: {
    exportPop,
  },

  data() {
    return {
      count: 0,

      exportstatus: false,

      promoterGoodsList: {},

      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '支付成功',
        },
        {
          value: 2,
          label: '未支付',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部投放平台',
        },
        {
          value: 1,
          label: '抖音',
        },
        // {
        //   value: 2,
        //   label: '快手',
        // },
        {
          value: 3,
          label: '今日头条',
        },
        {
          value: 4,
          label: '百度',
        },
        // {
        //   value: 5,
        //   label: '微信朋友圈',
        // },
        // {
        //   value: 6,
        //   label: '微信视频号',
        // },
      ],

      post: {
        status: 0,
        search: '',
        start_time: '',
        end_time: '',
        ca_id: this.$route.query.ca_id,
        filter_type: 2,
        channel: 0,
      },
      post2: {
        status: 0,
        ca_id: this.$route.query.ca_id,
        search: '',
        start_time: '',
        end_time: '',
        filter_type: 2,
        channel: 0,
      },
      orderTime: '',

      options3: [
        {
          value: 2,
          label: '昵称',
        },
        {
          value: 3,
          label: '真实姓名',
        },
        {
          value: 4,
          label: '手机号',
        },
      ],
    }
  },

  filters: {
    getChannel(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '抖音'
          break
        case 2:
          text = '快手'
          break
        case 3:
          text = '今日头条'
          break
        case 4:
          text = '百度'
          break
        case 5:
          text = '微信朋友圈'
          break
        case 6:
          text = '微信视频号'
          break
      }
      return text
    },
  },

  methods: {
    complete(val) {
      this.count = val.count
      this.promoterGoodsList = val.find
    },

    sortChange(value) {
      this.post2 = _.assign({}, this.post2, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },

    // 查看订单
    toOrder(row) {
      if (row.order_type == 1) {
        this.$router.push({
          path: `/capitalOrder/detail?data_id=${row.order_id}`,
        })
      } else {
        this.$router.push({
          path: `/capitalOrder/opencourseDetail/${row.order_id}`,
        })
      }
    },

    exportsubmit(val) {
      // 所有搜索条件get传递过去
      let link = val == 1 ? 'promotionCsv' : 'promotionExcel'

      if (this.count > 0) {
        let eleLink = document.createElement('a')
        eleLink.href = `${
          location.origin
        }/CourseAdvertising/${link}${this.objToStr(this.post2)}`
        eleLink.click()
        eleLink.remove()
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    // 导出
    exportTable() {
      this.exportstatus = true
    },

    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.post2.start_time = this.post.start_time = val[0] / 1000
      this.post2.end_time = this.post.end_time = val[1] / 1000 + 86399
    },
  },
}
</script>

<style lang="scss" scoped>
#promoterDetail {
  ::v-deep .el-descriptions-item__label {
    flex-shrink: 0;
  }

  // 超过有一行隐藏
  .one_overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::v-deep .el-descriptions-item__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .basic_data {
    margin: 20px;
    background-color: #fff;
    padding: 20px;
    padding-bottom: 8px;
    .tu {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  ::v-deep .main_menu {
    margin: 20px;
    background-color: #fff;
    .el-tabs__nav-wrap {
      padding: 0 30px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
}
.main {
  margin-top: 20px;
  background: #fff;
}
.header {
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ::v-deep .input-with-select .el-input__inner {
    width: 218px !important;
  }
  ::v-deep .search_select {
    .el-input__inner {
      width: 120px !important;
    }
  }
  ::v-deep .el-input-group {
    width: auto;
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
    }
  }
}
.heard_top {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  span {
    margin-right: 24px;
    min-width: 56px;
  }
  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
.paymember {
  display: flex;
  align-items: center;
  .member_photo {
    margin-right: 10px;
    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }
  .font1 {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cover {
  white-space: nowrap;
  .img {
    width: 28px;
    height: 28px;
    background: #f5f5f5;
    border-radius: 50%;
  }
  .text {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
    .t1 {
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .t2 {
      margin-top: 8px;
      font-size: 12px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 10px 0;
}
::v-deep .input2 {
  .el-input__inner {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
::v-deep .input3 {
  .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
